import { FC, ReactNode } from 'react';

import styles from './CustomHeader.module.scss';

import Badge, { Variant } from '@/components/Badge/Badge';
interface Props {
  numColumns: number;
  badge?: ReactNode;
  children: ReactNode;
  className?: string;
}
const CustomHeader: FC<Props> = ({
  numColumns,
  children,
  className,
  badge,
}) => {
  return (
    <tr className={className}>
      <th colSpan={numColumns}>
        {badge ? (
          <div className={styles.headerWithBadge}>
            {children}
            {<Badge variant={Variant.PURPLE}>{badge}</Badge>}
          </div>
        ) : (
          children
        )}
      </th>
    </tr>
  );
};

export default CustomHeader;
