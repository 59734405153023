/* eslint-disable @next/next/no-img-element */
import { FC } from 'react';

import styles from './Badge.module.scss';

interface BadgeAvatarProps {
  src?: string;
}

const BadgeAvatar: FC<BadgeAvatarProps> = ({ src }) => {
  if (!src) {
    return null;
  }
  return (
    <img
      referrerPolicy="no-referrer"
      src={src}
      className={styles.badgeAvatar}
      alt=""
    />
  );
};

export default BadgeAvatar;
