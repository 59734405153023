import { Action, SlimActionDto } from '@thegist-for-wix/api';
import { FC } from 'react';

import styles from './ActionTitle.module.scss';

const ActionTitle: FC<{ action: Action | SlimActionDto }> = ({ action }) => {
  return (
    <div className={styles.action}>
      <div className={styles.title}>{action.title}</div>
      <div className={styles.description} title={action.description}>
        {action.description}
      </div>
    </div>
  );
};

export default ActionTitle;
