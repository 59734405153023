import { PieChartDto } from '@thegist-for-wix/api';
import { Chart, ArcElement, Tooltip, Legend } from 'chart.js';
import { FC, useMemo } from 'react';
import { Doughnut as DoughnutChart } from 'react-chartjs-2';

import style from './Doughnut.module.css';
import { interpolateColors } from './interpolateColors';

import { ChartDataItem } from '../types';

import { Card } from '@/components/Card/Card';
import { capitalizeFirstLetter } from '@/src/utils';

const SEED_COLORS = ['#7F56D9', '#EAECF0'];
const LIMIT = 6;
const BRAND_COLORS = interpolateColors(
  SEED_COLORS[0],
  SEED_COLORS[1],
  LIMIT - 2,
);

export const mapPieChartDto = (chartDto: PieChartDto) => {
  return chartDto.data
    .filter((item) => item.name)
    .map((item) => {
      return { label: item.name, value: item.count };
    });
};

const MAX_ITEMS_SIZE = BRAND_COLORS.length;

Chart.register(ArcElement, Tooltip, Legend);

interface DoughnutProps {
  title: string;
  data: ChartDataItem[];
  onClick?: (element: ChartDataItem) => void;
}

const colorsForSize = (size: number) => {
  switch (size) {
    case 1:
      return [BRAND_COLORS[0]];
    case 2:
      return [BRAND_COLORS[0], BRAND_COLORS[3]];
    case 3:
      return [BRAND_COLORS[0], BRAND_COLORS[2], BRAND_COLORS[4]];
    case 4:
      return [
        BRAND_COLORS[0],
        BRAND_COLORS[2],
        BRAND_COLORS[3],
        BRAND_COLORS[5],
      ];
    case 5:
      return [
        BRAND_COLORS[0],
        BRAND_COLORS[1],
        BRAND_COLORS[2],
        BRAND_COLORS[3],
        BRAND_COLORS[4],
      ];

    default:
      return BRAND_COLORS;
  }
};

const sortAndAggregate = (
  data: ChartDataItem[],
  limit = LIMIT,
): ChartDataItem[] => {
  // Sort the array by the value property
  const sortedData = [...data].sort((a, b) => b.value - a.value);
  if (sortedData.length > limit) {
    // Calculate the number of items to aggregate
    const itemsToAggregate = sortedData.length - limit + 1;

    // Aggregate the last items into a new object
    const other = {
      label: 'Other',
      value: sortedData
        .slice(-itemsToAggregate)
        .reduce((sum, item) => sum + item.value, 0),
    };

    // Slice the original array to keep the first x items
    const remainingItems = sortedData.slice(0, limit - 1);

    // Return the combined array with "other" object at the end
    return [...remainingItems, other];
  }

  return sortedData;
};

export const Doughnut: FC<DoughnutProps> = ({ title, data, onClick }) => {
  const sortedData = useMemo(() => sortAndAggregate(data), [data]);
  const values = useMemo(
    () => sortedData.map((item) => item.value).slice(0, MAX_ITEMS_SIZE),
    [sortedData],
  );
  const labels = useMemo(
    () => sortedData.map((item) => item.label).slice(0, MAX_ITEMS_SIZE),
    [sortedData],
  );

  return (
    <Card>
      <h3 className={style.chartTitle}>{title}</h3>
      <div className={style.doughnutGrid}>
        <div className={style.doughnut}>
          <DoughnutChart
            data={{
              labels,
              datasets: [
                {
                  data: values,
                  borderWidth: 0,
                  backgroundColor: colorsForSize(
                    Math.min(MAX_ITEMS_SIZE, sortedData.length),
                  ),
                },
              ],
            }}
            options={{
              cutout: '80%',
              responsive: true,
              maintainAspectRatio: true,
              layout: { padding: 0 },
              plugins: {
                legend: {
                  display: false,
                },
              },
              onClick: (e, elements) => {
                if (!elements.length) {
                  return;
                }
                const index = elements[0].index;
                typeof onClick === 'function' &&
                  onClick({ label: labels[index], value: values[index] });
              },
            }}
            title={title}
          />
        </div>
        <ul>
          {labels.map((item, index) => (
            <li
              key={index}
              onClick={() =>
                typeof onClick === 'function' &&
                onClick({ label: labels[index], value: values[index] })
              }
            >
              <span
                className={style.legendColor}
                style={{
                  backgroundColor: BRAND_COLORS[index] ?? '',
                }}
              />
              {capitalizeFirstLetter(item)}
            </li>
          ))}
        </ul>
      </div>
    </Card>
  );
};
