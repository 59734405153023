/**
 * Delays the execution of subsequent code.
 * @param {number} ms - The amount of delay in milliseconds.
 * @returns {Promise} - Returns a Promise that resolves after the specified delay.
 */
export const delay = (ms: number) => new Promise((r) => setTimeout(r, ms));

export const capitalizeFirstLetter = (text: string) =>
  text.charAt(0).toUpperCase() + text.slice(1);

export const kebabCaseToSpace = (s: string) =>
  s.split('-').map(capitalizeFirstLetter).join(' ');

export const camelToSpaces = (str: string) =>
  str.replace(/([A-Z])/g, (match) => ` ${match.toLowerCase()}`).trim();

export const capitalizeEachWord = (str: string) =>
  str
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

export const segmentString = (text: string): string[] => {
  if (!text) {
    return [];
  }
  const segmenterEn = new Intl.Segmenter('en', { granularity: 'sentence' });
  const segments: Intl.Segments = segmenterEn.segment(text);
  const segmentsArr: Intl.SegmentData[] = Array.from(segments);

  return segmentsArr.map((s: Intl.SegmentData) => s.segment.trim());
};

export const formatNumber = (
  num: number | string,
  options: Intl.NumberFormatOptions = {
    maximumFractionDigits: 2,
    minimumFractionDigits: 0,
  },
) => {
  if (typeof num === 'string') {
    num = parseFloat(num);
  }
  return num.toLocaleString('en-US', options);
};

export const formatCurrency = (num: number) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    useGrouping: true,
    compactDisplay: 'long',
    unitDisplay: 'short',
    currencyDisplay: 'symbol',
    maximumFractionDigits: 0,
  }).format(num);
};

export const notNegative = (
  value: number | string | undefined,
  errorVal = 'N/A',
): number | string => {
  if (typeof value === 'undefined') {
    return errorVal;
  }

  if (typeof value === 'string') {
    try {
      return notNegative(parseFloat(value));
    } catch (error) {
      return errorVal;
    }
  }

  if (value < 0) {
    return 0;
  }

  return value;
};

export function genArray<T>(num: number, mapper: (index: number) => T): T[] {
  return new Array(num).fill(null).map((_, idx) => mapper(idx));
}
