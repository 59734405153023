import { genArray } from '@/src/utils';

type RGB = [number, number, number];

function toParts(color: string) {
  return genArray(3, (i) => color.slice(i * 2 + 1, i * 2 + 3));
}

function parseRGB(color: string): RGB {
  const parts = toParts(color);
  return parts.map((p) => parseInt(p, 16)) as RGB;
}

function parseHex(rgb: RGB) {
  return '#' + rgb.map((x) => x.toString(16)).join('');
}

function average(a: number, b: number, i: number, n: number) {
  return Math.round(a + i * ((b - a) / n));
}

function averageRGB([r1, g1, b1]: RGB, [r2, g2, b2]: RGB, n: number): RGB[] {
  return genArray(n, (i) => [
    average(r1, r2, i, n),
    average(g1, g2, i, n),
    average(b1, b2, i, n),
  ]);
}

// uniformly generate n colors between color1 and color2
export function interpolateColors(color1: string, color2: string, n: number) {
  const rgb1 = parseRGB(color1);
  const rgb2 = parseRGB(color2);
  const a = averageRGB(rgb1, rgb2, n);
  return [color1, ...a.map(parseHex), color2];
}
