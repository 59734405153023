import classNames from 'classnames';
import React, { FC, ReactNode } from 'react';

import style from './styles.module.css';

interface ThreeColumnsProps {
  children?: ReactNode;
  stretch?: boolean;
}

export const ThreeColumns: FC<ThreeColumnsProps> = ({ stretch, children }) => {
  return (
    <section
      style={{ ...(stretch ? { alignItems: 'stretch' } : {}) }}
      className={classNames(style.section, style.threeCollCardsSection)}
    >
      {children}
    </section>
  );
};
