import classNames from 'classnames';
import React, { FC, ReactNode } from 'react';

import style from './Card.module.css';

interface CardProps {
  children?: ReactNode;
  action?: ReactNode;
}

export const Card: FC<CardProps> = ({ action, children }) => {
  return (
    <div className={style.wrapper}>
      <div className={style.innerWrapper}>{children}</div>
      {action && (
        <div className={classNames(style.innerWrapper, style.action)}>
          {action}
        </div>
      )}
    </div>
  );
};
