import { Priority } from '@thegist-for-wix/api';
import { FC, ReactEventHandler } from 'react';

import Badge, { Variant } from '@/components/Badge/Badge';
export const priorityConfig: Record<
  Priority,
  { name: string; variant: Variant }
> = {
  [Priority.Category1]: { name: 'Category 1', variant: Variant.GREEN },
  [Priority.Category2]: { name: 'Category 2', variant: Variant.ORANGE },
  [Priority.Category3]: { name: 'Category 3', variant: Variant.RED },
  [Priority.None]: { name: 'N/A', variant: Variant.GRAY },
};

interface Props {
  value: Priority;
  onClick?: ReactEventHandler;
}
const Category: FC<Props> = ({ value, onClick }) => {
  return (
    <Badge
      variant={priorityConfig[value]?.variant ?? Variant.GRAY}
      title={value}
      onClick={onClick}
    >
      {priorityConfig[value]?.name || ''}
    </Badge>
  );
};

export default Category;
