import { FC } from 'react';

import { useDataTypes } from '../../context/dataTypes';
import Badge from '../Badge/Badge';

const FocusArea: FC<{ value: string }> = ({ value }) => {
  const { getFocusAreaVariant, getFocusAreaName } = useDataTypes();
  const variant = getFocusAreaVariant(value);
  const name = getFocusAreaName(value);
  return (
    <Badge title={name} variant={variant}>
      {name}
    </Badge>
  );
};

export default FocusArea;
