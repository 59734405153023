'use client';
import { Company, FocusArea, KpiGroup } from '@thegist-for-wix/api';
import { createContext, useContext } from 'react';

import {
  Variant,
  generateVariantFromStringValue,
} from '../components/Badge/Badge';

import { useApi } from '@/api';
import { iProviderProps } from '@/app/_providers/Providers';

interface iDataTypesContext {
  companies: Company[];
  focusAreas: FocusArea[];
  kpiGroups: KpiGroup[];
  getFocusAreaVariant: (focusArea: string) => Variant;
  getFocusAreaName: (focusArea: string) => string;
}

const variantNoop = () => Variant.GRAY;
export const DataTypesContext = createContext<iDataTypesContext>({
  companies: [],
  focusAreas: [],
  kpiGroups: [],
  getFocusAreaName: () => '',
  getFocusAreaVariant: variantNoop,
});

type StringKeys<T> = {
  [K in keyof T]: T[K] extends string ? K : never;
}[keyof T];

function getVariantsByArray<T>(
  arr: T[] | undefined,
  lookupKey: StringKeys<T>,
): Record<string, Variant> {
  return (
    arr?.reduce(
      (acc, val) => ({
        ...acc,
        [val[lookupKey] as string]: generateVariantFromStringValue(
          val[lookupKey] as string,
        ),
      }),
      {},
    ) || {}
  );
}

export const DataTypesProvider = ({ children }: iProviderProps) => {
  const { results: companies } = useApi(
    (api) =>
      api.companyControllerGetCompanies({
        limit: 200,
      }),
    [],
  );
  const { results: focusAreas } = useApi(
    (api) => api.focusAreaControllerGetFocusAreas(),
    [],
  );

  const { results: enrichedKpiGroups } = useApi(async (api) => {
    return (await api.bffKpiGroupControllerGetKpiGroupList()).items;
  }, []);

  const focusAreaVariants = getVariantsByArray(focusAreas, 'id');
  const focusAreaNames: Record<string, string> =
    focusAreas?.reduce(
      (acc, fa) => ({
        ...acc,
        [fa.id]: fa.name,
      }),
      {},
    ) || {};

  return (
    <DataTypesContext.Provider
      value={{
        companies: companies?.items || [],
        focusAreas: focusAreas || [],
        kpiGroups: enrichedKpiGroups?.map((e) => e.kpiGroup) || [],
        getFocusAreaVariant: (focusArea) => focusAreaVariants[focusArea],
        getFocusAreaName: (focusArea) => focusAreaNames?.[focusArea],
      }}
    >
      {children}
    </DataTypesContext.Provider>
  );
};

export const useDataTypes = () => useContext(DataTypesContext);
