import { Population } from '@thegist-for-wix/api';
import { FC } from 'react';

import Badge, { Variant } from '@/components/Badge/Badge';

const RelevantPopulationConfig: Record<
  Population,
  { name: string; variant: Variant } | undefined
> = {
  [Population.All]: { name: 'All', variant: Variant.GREEN },
  [Population.Developers]: { name: 'Developers', variant: Variant.ORANGE },
  [Population.Enterprise]: { name: 'Enterprise', variant: Variant.RED },
  [Population.InternalWix]: { name: 'Internal WIX', variant: Variant.PURPLE },
  [Population.Partners]: { name: 'Partners', variant: Variant.PINK },
  [Population.SelfCreators]: { name: 'Self Creators', variant: Variant.INDIGO },
  [Population.Other]: { name: 'Other', variant: Variant.BLUE },
  [Population.UpMarket]: { name: 'Upmarket', variant: Variant.GRAY },
};

const RelevantPopulation: FC<{ value: Population }> = ({ value }) => {
  return (
    <Badge
      title={value}
      variant={RelevantPopulationConfig[value]?.variant ?? Variant.GRAY}
    >
      {RelevantPopulationConfig[value]?.name || ''}
    </Badge>
  );
};

export default RelevantPopulation;
