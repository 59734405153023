import { SlimCompanyDto } from '@thegist-for-wix/api';
import Link from 'next/link';
import { FC } from 'react';

import Badge, { Variant } from '@/components/Badge/Badge';
import BadgeAvatar from '@/components/Badge/BadgeAvatar';

interface CompanyBadgeProps {
  company: SlimCompanyDto;
  largerText?: boolean;
}

const CompanyBadge: FC<CompanyBadgeProps> = ({ company, largerText }) => {
  if (!company) {
    return null;
  }

  return (
    <Badge
      variant={Variant.GRAY}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
      largerText={largerText}
    >
      <BadgeAvatar src={company?.owner?.avatarUrl} />
      <Link href={`/companies/${company.id}`}>{company?.name}</Link>
    </Badge>
  );
};

export default CompanyBadge;
